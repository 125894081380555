import React from 'react';

import './Sign.scss';
import '../Section/Section.scss';

import { graphql } from 'gatsby';
import ColorBlock from '../ColorBlock/ColorBlock';
import CTALink from '../CTA/CTALink';
import { sanitizer } from '../../util';

export default function Sign({ data }) {
  const { corporationCta, description, helpText, individualCta, title } = data;

  return (
    <ColorBlock className="SignCLA" color="gray">
      <section className="Section">
        <div className="container">
          <h3>{title}</h3>
          <p className="description">{description}</p>
          <div className="options">
            <CTALink text={individualCta.text} link={individualCta.link} />
            <CTALink text={corporationCta.text} link={corporationCta.link} />
          </div>
          <div className="help" dangerouslySetInnerHTML={{ __html: sanitizer(helpText) }} />
        </div>
      </section>
    </ColorBlock>
  );
}

export const fragments = graphql`
  fragment ClaSign on WpPage_Flexiblelayout_FlexibleChildren_ClaSign {
    corporationCta {
      link
      text
    }
    description
    helpText
    individualCta {
      link
      text
    }
    title
  }

  fragment ClaSign_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_ClaSign {
    corporationCta {
      link
      text
    }
    description
    helpText
    individualCta {
      link
      text
    }
    title
  }
`;
